export const CustomerMessages = {
  customerName: {
    required: 'Udfyld venligst feltet'
  },
  phoneNumber: {
    required: 'Udfyld venligst feltet'

  },
  pickupDate: {
    required: 'Udfyld venligst feltet'
  },
  phoneValidation: {
    invalid: 'Indtast venligst et gyldigt 8-cifret nummer'
  },
  email: {
    required: 'Udfyld venligst feltet'
  },
  emailValidation: {
    invalid: 'Indtast venligst en gyldig e-mail'
  }
};
