import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'numberFormat'
})
export class Numberformat implements PipeTransform {
  transform(n: string): string {
    if (n.length >= 2 ) {
      return n;
    }
    return n.padStart(2, '0');
  }
}
