// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appversion: '1.2.0',
    apiurl: 'https://quickcoopapi.quickinfo.dk/api/',
    oldURL: {
        firstTabUrl: 'https://quickcoopapi.quickinfo.dk/din-butik/',
        secondTabUrl: 'https://quickcoopapi.quickinfo.dk/nyheder-tilbud/',
        thirdTabUrl: 'https://quickcoopapi.quickinfo.dk/jatak/my-orders/',
    },
    newURL: {
        firstTabUrl: 'https://quickcoopapi.quickinfo.dk/local-offers/board',
        secondTabUrl: 'https://quickcoopapi.quickinfo.dk/local-offers/store',
        thirdTabUrl: 'https://quickcoopapi.quickinfo.dk/jatak/offers',
    },
    appUrl: 'https://coopshopnativetest.quickinfo.dk/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
