import {AbstractControl} from "@angular/forms";

export const emailValidator = (control: AbstractControl): {  [key: string]: { [key: string]: boolean }} | null => {
    const email = control.get('email').value.trim();
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.match(regex)) {
        return {
            emailValidation: {
                invalid: true
            }
        };
    }
    return null;
};
export const validatePhone =(control: AbstractControl): {  [key: string]: { [key: string]: boolean }} | null => {
    let phone = control.get('phoneNumber').value;
    if (phone === null) {
        return {
            phoneValidation: {
                invalid: true
            }
        };
    }
    phone = parseInt(phone, 10);
    if (isNaN(phone) || (phone.toString().length !== 8)) {
        return {
            phoneValidation: {
                invalid: true
            }
        };
    }
    return null;
};