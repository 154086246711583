import {Injectable} from '@angular/core';
import {CartStatus} from '../../shared/interface/cart';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CartService {
  constructor(private _apiService: ApiService) {
  }

  getCartDetails(): Observable<object> {
    const url = `customer/cart/active`;
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }
  getCartStatus(): Observable<CartStatus> {
    const url = 'customer/handle/cart';
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }
  postTestRoute(data: string, src: string): Observable<any> {
    const url = 'customer/test/route';
    const param = {
      route: data,
      source: src
    }
    return this._apiService.post(url, ApiService.CreateFormData(param));
  }

}
