import {AfterViewInit, Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {popupAnimation} from "../../animations/popupWIndow.animation";

@Component({
  selector: 'coop-service-not-available-modal',
  templateUrl: './service-not-available-modal.component.html',
  styleUrls: ['./service-not-available-modal.component.scss'],
  animations: [popupAnimation]
})
export class ServiceNotAvailableModalComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() information: string;
  buttonText: string = 'Tilbage';
  pAnimate = 'inactive';

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.pAnimate = 'active';
  }

  goToApp(): void {
    window.location.href = 'callback://close';
  }
}
