import { Component, OnInit } from '@angular/core';
import {TokenService} from '../../../core/services';

@Component({
  selector: 'coop-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  isTokenSet = false;
  constructor() { }

  ngOnInit(): void {
    this.isTokenSet = TokenService.isSet;
  }

}
