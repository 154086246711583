import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ServiceUnavailableData} from '../../../core/data';
import {
  AppEventsService,
  AppRouteService,
  CartService,
  ErrorhandlerService,
  TokenService
} from "../../../core/services";
import {takeWhile} from "rxjs/operators";
import {CartStatus} from "../../interface/cart";

@Component({
  selector: 'coop-cart-verification',
  templateUrl: './cart-verification.component.html',
  styleUrls: ['./cart-verification.component.scss']
})
export class CartVerificationComponent implements OnInit {
  REDIRECT_TO_SEARCH = '/coop/shop/search';
  FAILURE_ROUTE_TO_CART = 'coop/order/cart';
  SUCCESS_ROUTE_TO_RECEIPT = 'coop/shop/orders';
  storeKardex = '-1';
  cartStatus: CartStatus;
  private _subscriptionState = true;
  constructor(private _router: Router,
              private _cartService: CartService,
              private _routerService: AppRouteService,
              private _appEventService: AppEventsService,
              private _errorhandlerService: ErrorhandlerService) { }

  ngOnInit(): void {
    this.storeKardex = TokenService.storeKardex;
    this._getCartStatusAndRoute();
  }
  private _getCartStatusAndRoute(): void {
    this._cartService.getCartStatus().pipe(
        takeWhile(() => this._subscriptionState)
    ).subscribe(
        (cartStatus: CartStatus) => {
          this.cartStatus = cartStatus;
          if (!this.cartStatus.shopOpen) {
            this._handleServiceUnavailable();
            return;
          }
          this._handleRoutingFromAPIData();
        },
        err => {
          this._handleError(err);
        }
    );
  }
  private _handleServiceUnavailable(): void {
    this._router.navigate([this.REDIRECT_TO_SEARCH]).then(
        () => {
          this._routerService.clearRouteHistory();
          this._appEventService.showServiceUnavailableModal({
            show: true,
            title: ServiceUnavailableData.closed.title,
            information: ServiceUnavailableData.closed.information
          });
        }
    );
  }
  private _handleRoutingFromAPIData(): void {
    if (this.cartStatus.error) {
      this._errorhandlerService.handleError({code: this.cartStatus.code, message: this.cartStatus.message, error: true}, 'cart');
      if (+this.cartStatus.code >= 300 && +this.cartStatus.code < 400) {
        if (this.cartStatus.paid) {
          this._successRedirection(true);
          return;
        }
        if (!this.cartStatus.paid) {
          this._failureRedirection(true);
          return;
        }
      }
      this._failureRedirection(false);
      return;
    }
    if (this.cartStatus.status === 'success') {
      this._successRedirection(true)
      return;
    }
    if (this.cartStatus.status === 'failure') {
      this._failureRedirection(true);
      return;
    }
    this._routeToShopHome();
  }
  private _failureRedirection(saveData: boolean): void {
    if (saveData) {
      this._setAppPrevStatus('failure');
    }
    this.routeTo(this.FAILURE_ROUTE_TO_CART, true);
  }
  private _successRedirection(saveData: boolean): void {
    if (saveData) {
      this._setAppPrevStatus('success');
    }
    this.routeTo(this.SUCCESS_ROUTE_TO_RECEIPT, true);
  }
  private routeTo(url: string, setPrev: boolean): void {
    this._router.navigate([url]).then(() => {
      if (setPrev) {
        this._setPreviousUrl();
      }
    });
  }
  private _setPreviousUrl(): void {
    this._routerService.setPreviousRoute(`/coop/shop/${TokenService.storeKardex}`);
  }
  private _setAppPrevStatus(status: string): void {
    TokenService.storeId = this.cartStatus.shopId;
    TokenService.storeKardex = this.cartStatus.storeKardex;
    TokenService.storeName = this.cartStatus.shopName;
    TokenService.storeCvr = this.cartStatus.shopCvr;
    TokenService.storeNumber = this.cartStatus.shopPhoneNumber.toString().match(/.{1,2}/g).join(' ');
    TokenService.storeAddress = this.cartStatus.shopAddress;
    if (status === 'failure') {
      TokenService.cartId = this.cartStatus.cartId;
    }
    if (status === 'success') {
      TokenService.cartId = null;
    }
    TokenService.activeTab = '0';
  }
  private _routeToShopHome(): void {
    this.routeTo(`/coop/shop/${TokenService.storeKardex}`, false);
  }
  private _handleError(err): void {
    if (err.code === 1000) {
     // this.routeApp('-1');
    }
    this._errorhandlerService.handleError(err || {code: 400}, 'system');
  }

}
