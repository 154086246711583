export const ServiceUnavailableData = {
  closed: {
    title: 'Mad ud af huset er lukket',
    information: 'Vi holder lukket mellem 22.00-06.00, men er klar til at tage imod din bestilling i morgen tidlig. God aften!'
  },
  token: {
    title: 'Fejl',
    information: 'Der er opstået en fejl i systemet (token mangler).Prøv venligst igen senere.'
  }
};
