import {trigger, state, animate, transition, style} from '@angular/animations';

export const animateParent = [
    trigger('parentAnimate', [
        state('visible', style({
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        })),
        state('invisible', style({
            position: 'absolute',
            top: 0,
            left: '-100%',
            right: 0,
            bottom: 0,
            overflow: 'hidden'
        })),
        transition('visible <=> invisible', animate('600ms cubic-bezier(.35,0,.25,1)'))
    ])
];

export const animateChild = [
    trigger('childAnimate', [
        state('*', style({
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0
        })),
        transition(':enter', [
            style({
                left: '100%'
            }),
            animate('500ms cubic-bezier(.35,0,.25,1)', style({
                left: 0
            }))
        ]),
        transition(':leave', [
            animate('500ms cubic-bezier(.35,0,.25,1)', style({
                left: '100%'
            }))
        ])
    ])
];
