export const pickupdata = [
  {
    date: '2021-06-13',
    end: '00:00',
    holiday: true,
    start: '00:00'
  },
  {
    date: '2021-06-14',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-15',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-16',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-17',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-18',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-19',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-20',
    end: '00:00',
    holiday: false,
    start: '00:00'
  },
  {
    date: '2021-06-21',
    end: '00:00',
    holiday: false,
    start: '00:00'
  }
];
