import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Price, StoreHeader} from '../../interface';
import {NavigationEnd, Router} from '@angular/router';
import {AppRouteService, ErrorhandlerService, TokenService} from '../../../core/services';
import {takeWhile} from "rxjs/operators";

@Component({
  selector: 'coop-store-header',
  templateUrl: './store-header.component.html',
  styleUrls: ['./store-header.component.scss']
})
export class StoreHeaderComponent implements OnInit, OnDestroy {
  @Input() storeHeader: StoreHeader;
  @Input() formattedPrice: Price;
  @Input() bookingDate: string;
  currentUrl;
  storeName;
  private _subscriptionState: boolean = true;

  constructor(private _router: Router,
              private _routeService: AppRouteService,
              private _errorHandlerService: ErrorhandlerService) { }

  ngOnInit(): void {
    this.currentUrl = this._router.url;
    this.storeName = TokenService.storeName;
    this._router.events.pipe(
        takeWhile(() => this._subscriptionState)
    ).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }
    }, err => {
      this._handleError(err);
    });
  }
  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  goToCart(): void {
    this._router.navigate(['/coop/order/cart']).then(() => this._setPreviousUrl());
  }

  private _setPreviousUrl(): void {
    this._routeService.setPreviousRoute(this.currentUrl);
  }
  private _handleError(error): void {
    this._errorHandlerService.handleError(error || {code: 400}, 'system');
  }
}
