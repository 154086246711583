<div class="item-list">
  <ng-container *ngFor="let item of itemList">
    <a class="item" [class.category-list]=mode.category (click)="itemSelected(item.id)">
      <figure class="item-image" [ngClass]="aspectRatio? 'square': 'rectangle'" *ngIf="item.image">
        <img src="{{item.image}}" alt="" onerror="this.onerror=null; this.src='../../../assets/images/camera@3x.jpg'">
      </figure>
      <div class="item-detail" [class.with-price]=mode.product>
        <h3 class="item-title" *ngIf="mode.product">{{item.title}}</h3>
        <h3 class="item-title" *ngIf="mode.category">{{item.name}}</h3>
        <h3 class="item-title" *ngIf="mode.shop">{{item.name}}<br>{{item.address}}</h3>
        <div class="item-price" *ngIf="mode.product">
          {{item.price.base}}<sup>{{item.price.sup}}</sup>
        </div>
      </div>
      <ng-container *ngIf="!mode.product">
        <div class="link-arrow">
          <i class="icon-arrow-right"></i>
        </div>
      </ng-container>
    </a>
  </ng-container>
</div>

