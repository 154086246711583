<section class="sub-page-container">
  <div class="order-details-container sub-page">
    <div class="form-container" id="form-container">
      <form [formGroup]="customerForm">
        <div class="customer-details">
          <h3 role="heading" aria-label="Customer Information">Kundeoplysninger</h3>
          <div class="wrapper">
            <div class="row form-group" [class.error]="displayMessage['customerName']">
              <label for="customer-name">Navn</label>
              <input id="customer-name" type="text" placeholder="Indtast dit fulde navn" formControlName="customerName" aria-label="Customer Name" aria-placeholder="Please enter full name">
              <span class="error-message" *ngIf="displayMessage['customerName']">
                            {{displayMessage['customerName']}}
                        </span>
            </div>
            <div class="row form-group" [class.error]="displayMessage['phoneNumber'] || displayMessage['phoneValidation']">
              <label for="customer-number">Mobilnummer</label>
              <input id="customer-number" type="number" inputmode="tel"   autocomplete="off" placeholder="Indtast dit telefonnummer"
                     formControlName="phoneNumber" aria-label="Customer Number" aria-placeholder="Please enter telephone number" tabindex="0">
              <span class="error-message" *ngIf="displayMessage['phoneNumber'] || displayMessage['phoneValidation']">
                            {{displayMessage['phoneNumber'] || displayMessage['phoneValidation']}}
                        </span>
            </div>
            <div class="row form-group" [class.error]="displayMessage['email'] || displayMessage['emailValidation']">
              <label for="customer-email">E-mail adresse</label>
              <input id="customer-email" type="email" inputmode="email" placeholder="Indtast din e-mail"
                     formControlName="email" aria-label="Email" aria-placeholder="Please enter email">
              <span class="error-message" *ngIf="displayMessage['email'] || displayMessage['emailValidation']">
                            {{displayMessage['email'] || displayMessage['emailValidation']}}
                        </span>
            </div>
          </div>
        </div>
        <div class="terms">
          <div class="link-box">
            <div class="link-text margin-left-remove">
              <a  id="privacy-policy" tabindex="0" aria-label="Privacy Policy" class="u-red" href="{{coopPrivacyPolicy}}">Læs om</a>, hvordan Coop behandler dine personoplysninger som medlem i afsnit 1.4. i Coops privatlivspolitik,
            </div>
          </div>
        </div>
        <div class="order-details-container">
          <h3 role="heading" id="pickup" aria-label="Pickup">Afhentning</h3>
          <p id="minimum-pickup"  aria-label="Minimum Pickup Date">Tidligst {{minPickupDate | minpickupdateformat}}</p>
          <div class="row form-group" [class.error]="displayMessage['pickupDate']">
            <div class="date-picker" aria-label="Calendar">
              <label for="pickup-date" class="visually-hidden" aria-label="Calendar">Calendar</label>
              <mbsc-calendar id="pickup-date" [options]="dateSetting" formControlName="pickupDate" aria-label="Calendar"></mbsc-calendar>
            </div>
            <span class="error-message" *ngIf="displayMessage['pickupDate']">
                            {{displayMessage['pickupDate']}}
                        </span>
          </div>
          <div class="order-details">
            <p aria-label="Selected Pickup Point">Valgt afhentningspunkt: {{customerForm.value.pickupDate | pickupdateformat}}</p>
            <p aria-label="Pickup Location">Afhentningssted: {{shopOrderDetails.name}}, {{shopOrderDetails.address}}</p>
            <p aria-label="Phone Number">Tilf. {{shopOrderDetails.phoneNumber}}</p>
          </div>
        </div>
      </form>
    </div>
    <div class="legal-phrase">
      <p id="legal-phrase-one" aria-labelledby="legal-phrase-one">Du modtager en sms-bekræftelse når butikken har godkendt din ordre.</p>
      <p id="legal-phrase-two" aria-labelledby="legal-phrase-two">Der gælder ingen fortrydelsesret på dette køb, idet købet angår levnedsmidler, der forringes eller forældes hurtigt.</p>
    </div>
    <div class="terms">
      <h3 role="heading" aria-label="Terms">Vilkår</h3>
      <a role="button" tabindex="0" aria-label="Agree to Terms" class="term-1 link-box" (click)="agreeTermOne()">
        <div class="check icon-check-box" [ngClass]="agreedOne? 'green': 'grey'">
          <i class="icon-tick" *ngIf="agreedOne"></i>
        </div>
        <div class="link-text">
          <span id="alchohol-terms" aria-labelledby="alchohol-terms">Da der er alkohol i kurven bekræfter jeg at personen som afhenter ordren er over 16 år. (18 år hvis alkoholprocent er over 16,5%).Jeg er opmærksom på, at jeg ved afhentning kan blive bedt om at dokumentere min alder ved fremvisning af billedlegitimation.</span>
        </div>
      </a>
      <a class="term-2 link-box checkbox-link-box" tabindex="0"  aria-label="Agree terms">
        <label class="check icon-check-box container" for="checkbox">
          <input type="checkbox" tabindex="-1" id="checkbox" #checkbox aria-label="Agree to terms" [attr.aria-checked]="agreedTwo"  (change)="agreeTermTwo()" (click)="$event.stopPropagation()">
          <span class="checkmark" tabindex="0"></span>
        </label>
        <div class="link-text checkbox-link-text">
            <span id="accept" aria-labelledby="accept">Når jeg handler online i en lokal Coop butik godkender og accepterer jeg
              <a  class="u-red" aria-label="Terms and Condition Link" href='{{termsAndCondition}}' style="{pointer-events: auto}">salgs- og leveringsbetingelserne</a></span>
        </div>
      </a>
    </div>
    <div class="btn-container">
      <div class="wrapper">
        <div class="row" aria-label="Checkout">
          <button aria-label="Checkout"
                  class="btn btn-red"
                  (click)="goToPayment()">Gå til betaling
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
