<div class="popup-window">
    <div class="select-box-container" [@popUpBox]>
        <div class="select-box-content">
            <div class="select-box-title">
                <img src="assets/images/clock.svg" alt="">
                <h4>{{this.title}}</h4>
            </div>
            <div class="select-box-info">
                <p>{{this.information}}</p>
            </div>
        </div>
        <div class="select-btn">
                <div class="row">
                    <button class='btn btn-red'  (click)="goToApp()">{{buttonText}}</button>
                </div>
        </div>
    </div>
</div>