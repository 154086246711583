import {trigger, state, animate, transition, style, query, stagger, animateChild} from '@angular/animations';

export const popupAnimation = trigger('popUpBox', [
    transition(':enter', [
        style({top: '150%', opacity: 0}),
        animate('1.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
            style({top: '50%', opacity: 1})),

    ])
]);

