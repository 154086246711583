import {trigger, state, animate, transition, style} from '@angular/animations';

export const slideAnimation =
  [
    trigger('slideUpDown', [
      state('inactive', style({
        bottom: '-100vh',
        opacity: 0
      })),
      state('active', style({
        bottom: '0',
        opacity: 1
      })),
      transition('inactive <=> active', animate('300ms ease-in-out'))
    ]),
    trigger('slideLeftRight', [
      state('inactive', style({
        left: '-50vh'
      })),
      state('active', style({
        left: '0px'
      })),
      transition('inactive <=> active', animate('400ms ease-in-out'))
    ])
  ];
