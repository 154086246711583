import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dayandmonth'
})
export class DayAndMonth implements PipeTransform {
  transform(date: string): string {
    moment.locale('da');
    return moment(date).format('DD MMM');
  }
}
@Pipe({
  name: 'minutestransform'
})
export class MinutesTransform implements PipeTransform {
  transform(minutes: number): string {
    const day = moment.duration({minutes}).days();
    const hour = moment.duration({minutes}).hours();
    const minute = moment.duration({minutes}).minutes();
    let dayHourMin = '';
    if (day !== 0) {
      dayHourMin += ` ${day} d.`;
    }
    if (hour !== 0) {
      dayHourMin += ` ${hour} t.`;
    }
    if (minute !== 0) {
      dayHourMin += ` ${minute} min.`;
    }
    return dayHourMin;
  }
}
@Pipe({
  name: 'dateformatddmmyy'
})
export class DateFormatDDMMYY implements PipeTransform {
  transform(date: string): string {
    return moment(date).format('DD-MM-YYYY HH:mm');
  }
}

@Pipe({
  name: 'pickupdateformat'
})
export class PickupDateFormat implements PipeTransform {
  transform(date: string): string {
    moment.locale('da');
    return moment(date).format('D MMM[.] YYYY [kl.] HH:mm');
  }
}
@Pipe({
  name: 'checkoutpickupdateformat'
})
export class CheckoutPickupDateFormat implements PipeTransform {
  transform(date: string): string {
    moment.locale('da');
    return moment(date).format('D MMM[.] YYYY');
  }
}

@Pipe({
  name: 'minpickupdateformat'
})
export class MinPickupDateFormat implements PipeTransform {
  transform(date: string): string {
    moment.locale('da');
    return moment(date).format('dddd [den] D MMM[.] YYYY [kl.] HH:mm');
  }
}

@Pipe({
  name: 'datetostring'
})
export class DateToString implements PipeTransform {
  transform(date: Date): string {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}
