import {Component, OnInit, Renderer2} from '@angular/core';
import {HeaderService} from '../../../core/services/header.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppEventsService, AppRouteService, NotificationService} from '../../../core/services';

@Component({
    selector: 'coop-iframe-container',
    templateUrl: './iframe-container.component.html',
    styleUrls: ['./iframe-container.component.scss']
})
export class IframeContainerComponent implements OnInit {
    pageTitle;
    activeTab;
    url;
    isJatakDetailActive = false;

    constructor(private _headerService: HeaderService,
                private _activatedRoute: ActivatedRoute,
                private _renderer: Renderer2,
                private _router: Router,
                private _appEventService: AppEventsService,
                private _routerService: AppRouteService) {
    }

    ngOnInit(): void {
        this._renderer.listen('window', 'message', (event) => {
            if (event.data.source === 'NYHEDER-TILBUD') {
                this.activeTab = 3;
                this._appEventService.tabChange(3);
                this._router.navigate([`/coop/iframe-content/3`]);
            }
            if (event.data.source === 'NYHEDER-TILBUD_TAB') {
                this.activeTab = 2;
                this._appEventService.tabChange(2);
                this.isJatakDetailActive = true;
                this.url = event.data.url;
                this._router.navigate([`/coop/iframe-content/4`]);
            }
        });
        this._activatedRoute.params.subscribe(params => {
            this._load(+params['tab']);
        });
    }

    onFrameLoad(): void {
        this._appEventService.showLoader(false);
    }

    private _load(tab: number): void {
        this._appEventService.showLoader(true);
        this.activeTab = tab;
        this._loadPageData();
    }

    private _loadPageData(): void {
        if (this.activeTab === 2) {
            this._headerService.setHeader('Din Butik', true, 'none', true);
        }
        if (this.activeTab === 1) {
            this._headerService.setHeader('Din Butik', true, 'none', true);
        }
        if (this.activeTab === 3) {
            this._headerService.setHeader('Dine Ja Tak', true, 'none', true);
        }
        if (!this.isJatakDetailActive) {
            this.url = this._routerService.getTabRoutes(this.activeTab);
        }
        this.isJatakDetailActive = false;
    }


}
