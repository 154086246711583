import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CartDetails} from '../../shop/interface';
import {ErrorhandlerService} from './errorhandler.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CartService} from './cart.service';


@Injectable({
  providedIn: 'root'
})
export class CartPriceResolverService implements Resolve<CartDetails> {
  constructor(private _cartService: CartService,
              private _errorHandlerService: ErrorhandlerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<CartDetails> {
    return this._cartService.getCartDetails().pipe(
      catchError(err => this._errorHandlerService.routeResolverError(err))
    );
  }
}
