import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppRouteService} from '../../../core/services/approute.service';
import {HeaderService} from '../../../core/services/header.service';
import {takeWhile} from 'rxjs/operators';
import {TokenService} from '../../../core/services/token.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'coop-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {
  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();
  @Input() activeTab = 0;
  pageTitle;
  showTab = true;
  bgColor = 'none';
  goToApp = false;
  env = environment;
  appversion ='';
  private _subscriptionState: boolean = true;
  constructor(private _router: Router,
              private _location: Location,
              private _headerService: HeaderService,
              private _routerService: AppRouteService) {
  }

  ngOnInit(): void {
    this.activeTab = +TokenService.activeTab;
    this._setPaddingTop();
    this._headerService.getHeader().pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe(headerData => {
      this.pageTitle = headerData[0];
      this.showTab = headerData[1];
      this.bgColor = headerData[2];
      this.goToApp = headerData[3];
      setTimeout(() => {
        const tabs = document.getElementById('page-header-tabs');
        if (tabs) {
          const activeTab =  tabs.getElementsByClassName('active')[0];
          // bringing the hent-mad tab in view
          activeTab.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 200);
    });
    this.appversion = TokenService.appVersion;
  }

  back(): void {
    const route = this._routerService.getPreviousUrl();
    if (!this.goToApp && route && route !== '/') {
      this._router.navigate([route]).then();
    }
    if (this.goToApp || route === '/' || !route) {
      window.location.href = 'callback://close';
    }
  }

  tab(option: number, selectedTab: any): void {
    this.activeTab = option;
    selectedTab.scrollIntoView({
      behavior: 'smooth'
    });
    this.tabSelected.emit(option);
  }

  private _setPaddingTop(): void {
    const appHeader = document.getElementById('app-header');
    appHeader.style.paddingTop = (TokenService?.paddingTop || 0) + 'px';
  }
}
