import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {mobiscroll} from '@mobiscroll/angular';
import {Error} from '../../shared/interface';
import {ErrorMessages} from '../data';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {

  constructor(private _router: Router) {
  }

  // *** Show General Error Messages ***
  // @params [errorMessage] => Message to be displayed
  // show error messages that are not from api
  static error(errorMessage, error?): void {
    if (!environment.production && error) {
      console.log(error);
    }
    mobiscroll.snackbar({
      message: errorMessage,
      display: 'top',
      color: 'danger',
      duration: 7000
    }).then();
  }

  // *** Handle Errors from api ***
  // @params [error] => Error object generate by the api
  // @params @optional [errorof] => Error of which module eg. Offer, Polls etc
  // Check which module the error belongs to
  // Find the relate error message using the error code
  // Show the error message
  // If the error is due to Invalid user, usertype Logout
  handleError(error: Error, errorOf?: string): void {
    errorOf = errorOf || 'system';
    if (!environment.production) {
      console.log(error, errorOf);
    }
    const errorMessage = (error.code && ErrorMessages[errorOf][error.code]) ?
      ErrorMessages[errorOf][error.code]
      : 'Noget gik galt. Prøv igen';

    mobiscroll.snackbar({
      message: errorMessage,
      display: 'top',
      color: 'danger',
      duration: 7000
    }).then();
  }


  // *** Route to home page if any error occurs on route resolve ***
  // @params [error] => Error created by resolver
  // route to homw page
  // @returns [Observable] => error as an observable that route resolver can handle
  routeResolverError(error): Observable<any> {
    this.handleError(error);
    this._router.navigate(['/404']).then();
    return of(error);
  }
}
