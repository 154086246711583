export const ErrorMessages = {
  system: {
    83: 'Sessionen er udløbet. Log venligst ind igen.',
    380: 'Basket error - OAuth-validering mislykkedes',
    401: 'Uberettiget',
    403: 'Forbudt',
    404: 'Noget gik galt. Prøv igen',
    405: 'Metode ikke tilladt',
    500: 'Noget gik galt. Prøv igen',
    '-1': 'Token udløbet',
    1000: 'Medlem API: Store-hentningsfejl'
  },
  product: {
    43: 'Produktet blev ikke fundet eller er udløbet!'
  },
  shopSearch: {
    400: 'Venligst tjek / udfyld alle felter',
    380: 'Basket error - OAuth-validering mislykkedes'
  },
  cart: {
    3: 'Kurv kan ikke redigeres i den aktuelle tilstand. For at fortsætte skal du gå til kassen eller slette vognen.',
    400: 'Venligst tjek / udfyld alle felter',
    30: 'Ugyldig vare',
    31: 'Butikken stemmer ikke overens',
    32: 'De to varer kan ikke afhentes inden for samme periode og skal derfor bestilles separat. Beklager ulejligheden.',
    34: 'Vognen blev ikke fundet',
    35: 'Ugyldig afhentningsdato',
    36: 'Kurvgenstand ikke fundet',
    37: 'Vognen er allerede tjekket ud',
    38: 'Produkt fra en anden kategori kan ikke tilføjes.',
    41: 'Udsolgt',
    42: 'Produktkøbskvoten pr. Dag er overskredet',
    300: 'Basket error - Produktet med den medfølgende SKU blev ikke fundet',
    301: 'Basket error - Produktet med den medfølgende SKU er låst og kan ikke købes online',
    302: 'Basket error - Vognen kan ikke redigeres i den aktuelle tilstand',
    303: 'Basket error - En eller flere validerings fejl opstod under behandlingen af anmodningen',
    304: 'Basket error - Terminalen med det angivne id blev ikke fundet',
    305: 'Basket error - Butikken med det angivne id blev ikke fundet',
    306: 'Basket error - Kunden med det angivne id blev ikke fundet',
    307: 'Basket error - Scan & Pay er ikke tilgængelig i butikken',
    308: 'Basket error - Den angivne værdi er ikke gyldig',
    309: 'Basket error - Produktet med den medfølgende SKU er ikke konfigureret og kan ikke købes',
    330: 'Basket error - Adgangstoken mangler eller er ugyldigt',
    350: 'Basket error - Kurv ikke fundet',
    351: 'Basket error - Elementet med det angivne id blev ikke fundet',
    370: 'Basket error - Der er opstået en fejl i en af systemafhængighederne',
    371: 'Basket error - En afhængighed er udløbet, mens anmodningen behandles',
    380: 'Basket error - OAuth-validering mislykkedes',
    381: 'Basket error - Noget gik galt. Prøv igen',
    375: 'Basket error - Kan ikke udfylde kurven før kassen',
    376: 'Basket error - Kurven er tom og kan ikke tjekkes ud',
    377: 'Basket error - Systemet er ikke tilgængeligt i øjeblikket / er i vedligeholdelsestilstand',
    378: 'Basket error - Klientversionen understøttes ikke og kræver en opdatering',
    379: 'Basket error - Handlingen er ikke tilgængelig, mens kurven er i denne tilstand',
  }
};
