import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppEventsService} from './appevents.service';
import {environment} from '../../../environments/environment';
import {ErrorhandlerService} from './errorhandler.service';
import {TokenService} from './token.service';
import {Router} from '@angular/router';
import {AppRouteService} from './approute.service';
import {ServiceUnavailableData} from '../data';


@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
    REDIRECT_TO_SEARCH = '/coop/shop/search';

    constructor(private _appEvents: AppEventsService,
                private _router: Router,
                private _routerService: AppRouteService,
                private _errorHandlerService: ErrorhandlerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(this._setRequestHeaders(req))
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this._closeLoader();
                    }
                    return event;
                }),
                catchError((HttpError: HttpErrorResponse) => {
                    this._closeLoader();
                    if (HttpError.error.code === 44) {
                        this._router.navigate([this.REDIRECT_TO_SEARCH]).then(() => {
                            this._routerService.clearRouteHistory();
                            this._appEvents.showServiceUnavailableModal({
                                show: true,
                                title: ServiceUnavailableData.closed.title,
                                information: ServiceUnavailableData.closed.information
                            });
                        });
                    }
                    if (HttpError.error.code === 1000) {
                        this._errorHandlerService.handleError(HttpError.error || {code: 400}, 'system');
                    }
                    if (HttpError.status === 401) {
                        this._router.navigate([this.REDIRECT_TO_SEARCH]).then(() => {
                            this._routerService.clearRouteHistory();
                            this._appEvents.showServiceUnavailableModal({
                                show: true,
                                title: ServiceUnavailableData.token.title,
                                information: ServiceUnavailableData.token.information
                            });
                        });
                    }
                    return throwError(HttpError.error);
                })
            );
    }

    private _setRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        const headerObject = {};
        if (request.method === 'POST' && !(request.body instanceof FormData)) {
            headerObject['Content-type'] = 'application/x-www-form-urlencoded';
        }
        if (request.method === 'GET') {
            headerObject['Content-type'] = 'application/json';
        }
        if (request.url.indexOf(environment.apiurl) !== -1) {
            if (TokenService.jwtToken) {
                headerObject['Authorization'] = `Bearer ${TokenService.jwtToken}`;
                headerObject['X-Customer-Id'] = TokenService.subId;
            }
        }

        this._appEvents.showLoader(true);
        const headers = new HttpHeaders(headerObject);
        return request.clone({headers});
    }

    private _closeLoader(): void {
        this._appEvents.showLoader(false);
    }
}
