import {Injectable} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  get window(): any {
    return _window();
  }
  isMobileDevice(width): boolean {
    return width < 768;
  }

  onWindowResize(): any {
    return fromEvent(window, 'resize')
      .pipe(
        map(() => {
          return {
            width: this.window.document.documentElement.clientWidth,
            height: this.window.document.documentElement.clientHeight
          };
        }),
        debounceTime(200)
      );
  }
}
