<header class="app-header product-header clearfix not-print">
  <div class="wrapper">
    <div class="shop-and-cart">
        <div class="shop-info" *ngIf="!storeHeader.cartOnly && !storeHeader.pickUpTime" [class.store-header]="storeHeader.shopOnly">
          <span><i class="ti-location-pin"></i>{{storeName}}</span>
        </div>
      <div class="shop-info" *ngIf="storeHeader.pickUpTime">
        <span><i class="ti-time"></i>{{bookingDate | dateformatddmmyy}}</span>
      </div>
      <a class="btn btn-green cart-btn" (click)="goToCart()" *ngIf="!storeHeader.shopOnly && !storeHeader.pickUpTime">
          <img src="./assets/images/cartImage.svg" class="icon-shopping" alt="Cart Image">
        <div class="link-text">
          <span *ngIf="!formattedPrice">0<sup>0</sup></span>
          <span *ngIf="formattedPrice">{{formattedPrice.base}}<sup>{{formattedPrice.sup}}</sup></span>
        </div>
      </a>
    </div>
  </div>
</header>
