import {Location} from '@angular/common';
import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {AppRouteService, TokenService} from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'coop-order-pickup';
  appVersion = environment.appversion;
  constructor(private _routerService: AppRouteService) {
    console.log(`Version: ${this.appVersion}`);
  }
  ngOnInit(): void {
  }
  back(): void {
    const route = this._routerService.getPreviousUrl();
    if (route === '/' || !route) {
      window.location.href = 'callback://close';
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(): void {

    const currentUrl = this._routerService.getCurrentAppRoute();
    if (currentUrl.includes('coop/iframe-content/') || currentUrl === `/coop/shop/${TokenService.storeKardex}`) {
      window.location.href = 'callback://close';
      return;
    }
    this.back();
  }
}
