import {Injectable} from '@angular/core';
import {AppEventsService} from './appevents.service';
import {Observable, zip} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(private _appEventService: AppEventsService) {
  }
  setHeader(title: string, showTab: boolean, background: string, go: boolean): void {
    this._appEventService.setPageTitle(title);
    this._appEventService.showTab(showTab);
    this._appEventService.setBgColor(background);
    this._appEventService.goToApp(go);
  }
  getHeader(): Observable<any> {
    return zip(this._appEventService.$pageTitle,
      this._appEventService.$showTab,
      this._appEventService.$bgColor,
      this._appEventService.$backToApp);
  }
}
