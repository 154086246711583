import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemList, ListMode} from '../../interface';

@Component({
  selector: 'coop-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
  @Input() aspectRatio = true; // true : square(50x50), false: rectangle
  @Input() itemList: ItemList[];
  @Input() url: string;
  @Input() mode: ListMode;
  @Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  itemSelected(id: string): void {
    this.selectedItem.emit(id);
  }

}
