import {Injectable} from '@angular/core';
import {AppEventsService} from './appevents.service';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AppRouteService {
  routeHistory = ['/'];
  tabRoutes = [];
  currentAppRoute = '';
  currentIndex = 0;
  constructor(private _appEventService: AppEventsService) {
  }
  setPreviousRoute(url: string): void {
    this.routeHistory.push(url);
    this.currentIndex++;
  }
  checkPreviousUrl(url: string) : boolean {
    const prevUrlInHistory = this.routeHistory[this.routeHistory.length - 1];
    if (url === prevUrlInHistory) {
      return true;
    }
    return false;
  }
  getPreviousUrl(): string {
    let url;
    if (this.currentIndex === 0) {
      url = this.routeHistory[0];
    }
    if (this.currentIndex > 0) {
      url = this.popUrl();
      this.currentIndex--;
    }
    return url;
  }

  popUrl(): string {
    return this.routeHistory.pop();

  }

  setCurrentAppRoute(route: string): void {
    this.currentAppRoute = route;
  }
  getCurrentAppRoute(): string {
    if (this.currentAppRoute === '') {
      return `/coop/shop/${TokenService.storeKardex}`;
    }
    return this.currentAppRoute;
  }
  setTabRoutes(firstTabUrl: string, secondTabUrl: string, thirdTabUrl: string): void {
    this.tabRoutes.push(firstTabUrl);
    this.tabRoutes.push(secondTabUrl);
    this.tabRoutes.push(thirdTabUrl);
  }
  getTabRoutes(activeTab: number): string {
    if (activeTab === 1 || activeTab === 2 || activeTab === 3) {
      return this.tabRoutes[activeTab - 1];
    }
    return '';
  }
  clearRouteHistory(): void {
    this.routeHistory = ['/'];
  }
}
