<div class="app-page" id="app-page">
  <div class="header-container">
    <div class="bg-container">
      <div class="overlay"></div>
    </div>
    <div class="background-overlay-container fixed-header" id="bg-overlay-container">
      <coop-pageheader [activeTab]="activeTab" (tabSelected)="tab($event)" ></coop-pageheader>
      <ng-container *ngIf="showStoreHeader">
        <coop-store-header [storeHeader]="headerMode"></coop-store-header>
      </ng-container>
    </div>
  </div>
  <div id="main-page-container">
    <div class="opaque-background" [class.remove-opaque] = "!showOpaque">
      <div class="opaque-background-header" [class.remove-opaque] = "!showOpaqueHeader"></div>
      <div class="opaque-background-body"></div>
    </div>
    <main [@routeAnimations] = "prepareRoute(outlet)">
      <router-outlet #outlet="outlet" ></router-outlet>
    </main>
  </div>
  <coop-service-not-available-modal *ngIf="activeTab === 0 && serviceNotAvailableModal.show"
                                    [title]="serviceNotAvailableModal.title"
                                    [information]="serviceNotAvailableModal.information"></coop-service-not-available-modal>
</div>
