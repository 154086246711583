import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ServiceUnavailable} from '../../shared/interface';

@Injectable({
  providedIn: 'root'
})
export class AppEventsService {
  //  for data receive from app
  private _dataReceive = new Subject<string>();
  $dataReceive = this._dataReceive.asObservable();

  // show tab
  private _showTab = new Subject<boolean>();
  $showTab = this._showTab.asObservable();

  // tab change
  private _tabChange = new Subject<any>();
  $tabChange = this._tabChange.asObservable();
  // page title
  private _pageTitle = new Subject<string>();
  $pageTitle = this._pageTitle.asObservable();

  // bg color
  private _bgColor = new Subject<string>();
  $bgColor = this._bgColor.asObservable();

  // go back to app
  private _backToApp = new Subject<boolean>();
  $backToApp = this._backToApp.asObservable();

  // Show Hide Loader
  private _showLoader = new Subject<boolean>();
  $showLoader = this._showLoader.asObservable();


  // show loader msg
  private _loaderMsg = new Subject<string>();
  $loaderMsg = this._loaderMsg.asObservable();

  private _consoleMsg = new Subject<string>();
  $consoleMsg = this._consoleMsg.asObservable();
  private _consoleMsg1 = new Subject<string>();
  $consoleMsg1 = this._consoleMsg1.asObservable();
  private _showServiceUnavailableModal = new BehaviorSubject<ServiceUnavailable>({show: false, title: '', information: ''});
  $showServiceUnavailableModal = this._showServiceUnavailableModal.asObservable();

  constructor() {
  }
  // set data receive key
  setDataReceived(state: string): void {
    this._dataReceive.next(state);
  }

  // Set show tab
  showTab(state: boolean): void {
    this._showTab.next(state);
  }

  // set Tab change
  tabChange(state: any): void {
    this._tabChange.next(state);
  }

  // Set current page title
  setPageTitle(pageTitle: string): void {
    this._pageTitle.next(pageTitle);
  }

  // Set current bg color
  setBgColor(color: string): void {
    this._bgColor.next(color);
  }

  // Go back to app
  goToApp(go: boolean): void {
    this._backToApp.next(go);
  }

  // Show Loader
  showLoader(state: boolean): void {
    this._showLoader.next(state);
  }

  showServiceUnavailableModal(state: ServiceUnavailable): void {
  this._showServiceUnavailableModal.next(state);
  }
}

