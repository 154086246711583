import { Component, OnInit } from '@angular/core';
import {AppEventsService} from '../../../core/services';

@Component({
  selector: 'coop-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  showLoader: boolean = false;
  loaderMsg = '';
  constructor(private _appEventsService: AppEventsService) { }

  ngOnInit(): void {
    this._appEventsService.$showLoader
      .subscribe(
        showLoader => this.showLoader = showLoader
      );
    this._appEventsService.$loaderMsg
      .subscribe(
        loaderMsg => this.loaderMsg = loaderMsg
      );
  }
}
