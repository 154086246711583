import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OrderService} from './order.service';
import {CartItemData, Product, ProductListData, ShopDetails} from '../interface';
import {EMPTY, Observable, throwError} from 'rxjs';
import {ErrorhandlerService, TokenService, UserService} from '../../core/services';
import {catchError, mergeMap} from 'rxjs/operators';
import {Error} from '../../shared/interface/error.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductListResolverService implements Resolve<ProductListData> {
  constructor(private _orderService: OrderService,
              private _errorHandlerService: ErrorhandlerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProductListData> {
    const shopId = + route.params.shopId;
    const categoryId = + route.params.categoryId;
    return this._orderService.getProductListing(shopId, categoryId).pipe(
      catchError(err => this._errorHandlerService.routeResolverError(err))
    );

  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductResolverService implements Resolve<Product> {
  constructor(private _orderService: OrderService,
              private _errorHandlerService: ErrorhandlerService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<Product> | null {
    const shopId = + route.params.shopId;
    const id = + route.params.productId;
    return this._orderService.getSingleProduct(shopId, id).pipe(
          catchError((err: Error) => {
            if (err.code === 43) {
              this._errorHandlerService.handleError(err, 'product')
              return throwError(err);
            }
            return this._errorHandlerService.routeResolverError(err)
          })
      );

  }
}

@Injectable({
  providedIn: 'root'
})
export class CartResolverService implements Resolve<CartItemData> {
  constructor(private _orderService: OrderService,
              private _errorHandlerService: ErrorhandlerService,
              private _userService: UserService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<CartItemData> {
    if (!TokenService.cartId) {
      return;
    }
    return this._orderService.getCartItems().pipe(
      catchError(err => this._errorHandlerService.routeResolverError(err))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class CartItemResolverService implements Resolve<Product> {
  constructor(private _orderService: OrderService,
              private _errorHandlerService: ErrorhandlerService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<Product> {
    const id = + route.params.productId;
    return this._orderService.getCartItem(id).pipe(
      catchError(err => this._errorHandlerService.routeResolverError(err))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class PickUpDateResolverService implements Resolve<ShopDetails> {
  constructor(private _orderService: OrderService,
              private _errorHandlerService: ErrorhandlerService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<ShopDetails> {
    return this._orderService.getPickUpDate().pipe(
      catchError(err => this._errorHandlerService.routeResolverError(err))
    );
  }
}

