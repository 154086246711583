import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AccessibilityComponent} from './accessibility/accessibility.component';
import {AppComponent} from './app.component';
import {AuthGuard, UserGuard, VerifyGuard} from './core/guards';

import {ComingSoonComponent, MainComponent, UserVerificationComponent} from './shared/components';
import {ErrorPageComponent} from './shared/components/error-page/error-page.component';
import {IframeContainerComponent} from './shared/components/iframe-container/iframe-container.component';
import {CartVerificationComponent} from './shared/components/cart-verification/cart-verification.component';

const routes: Routes = [
  {
    path: 'verify',
    component: UserVerificationComponent,
    canActivate: [VerifyGuard]
  },
  {
    path: 'accessibility',
    component: AccessibilityComponent
  },
  {
    path: 'coop',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'iframe-content/:tab',
        component: IframeContainerComponent
      },
      {
        path: 'cart-verify',
        component: CartVerificationComponent
      },
      {
        path: 'shop',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
        data: {
          animation: 'shop'
        }
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
        data: {
          animation: 'order'
        }
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent
      }
    ]
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [UserGuard]
  },
  {
    path: '404',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
