import {Injectable} from '@angular/core';
import {ApiService, TokenService} from '../../core/services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CartItemData, Product, ProductListData} from '../interface';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  constructor(private _apiService: ApiService) {
  }

  getProductListing(shopId: number, categoryId: number): Observable<ProductListData> {
    const url = `customer/shop/${shopId}/category/${categoryId}`;
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  getSingleProduct(shopId: number, id: number): Observable<Product> {
    let url = `customer/shop/${shopId}/product/${id}`;
    if (TokenService.cartId) {
      url += `?cartId=${TokenService.cartId}`;
    }
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  addToCart(params: Product, editMode: boolean, itemId?: number): Observable<object> {
    if (editMode) {
      const cartId = TokenService.cartId;
      return this._apiService.put(`customer/cart/${cartId}/item/${itemId}`, ApiService.CreateFormData(params));
    }
    return this._apiService.post(`customer/cart`, ApiService.CreateFormData(params)).pipe(
      map(
        res => res['data']
      )
    );
  }

  addItem(params: Product): Observable<object> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}/item`;
    return this._apiService.post(url, ApiService.CreateFormData(params)).pipe(
      map(
        res => res['data']
      )
    );
  }

  getCartItems(): Observable<CartItemData> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}`;
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  clearCart(): Observable<object> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}`;
    return this._apiService.del(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  getCartItem(id: number): Observable<Product> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}/item/${id}`;
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  deleteCartItem(id: number): Observable<CartItemData> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}/item/${id}`;
    return this._apiService.del(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  getPickUpDate(): Observable<object> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}/pickUpDate`;
    return this._apiService.get(url).pipe(
      map(
        res => res['data']
      )
    );
  }

  checkout(params): Observable<object> {
    const cartId = TokenService.cartId;
    const url = `customer/cart/${cartId}/checkout`;
    return this._apiService.post(url, ApiService.CreateFormData(params)).pipe(
      map(
        res => res['data']
      )
    );
  }
}
