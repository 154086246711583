import {trigger, state, animate, transition, style} from '@angular/animations';
import {TokenService} from '../../core/services';
const topShiftFixUp = `${TokenService.paddingTop * -1}px`;
export const fadeInOutAnimation =
  trigger('fadeInOutAnimation', [
    state('*', style({
      position: 'absolute',
      top: topShiftFixUp,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 50,
      height: '100vh',
      width: '100%'
    })),
    transition(':enter', [
      style({
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }),
      animate('.1s cubic-bezier(.35,0,.25,1)', style({
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      }))
    ]),
    transition(':leave', [
      animate('.1s cubic-bezier(.35,0,.25,1)', style({
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }))
    ])
  ]);

export const detailOverlayAnimation =
  [
    trigger('slideUpDown', [
      state('inactive', style({
        top: '100%',
        opacity: 0
      })),
      state('active', style({
        top: 0,
        opacity: 1
      })),
      transition('inactive <=> active', animate('600ms cubic-bezier(.35,0,.25,1)'))
    ])
  ];
