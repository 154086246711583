import {Injectable} from '@angular/core';
import {mobiscroll} from '@mobiscroll/angular';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() {
  }

  // *** Show Notifications ***
  // @params [notificationMessage] => Message to be displayed
  static notify(notificationMessage: string, type: string, position: string): void {
    mobiscroll.snackbar({
      message: notificationMessage,
      color: type,
      display: position,
      duration: 7000,
        button: {
            text: '',
            action: function () {
                mobiscroll.notification.dismiss
            }
        },
      theme: 'ios'
    }).then();
  }
  static confirmWithCallbackCustom(confirmationMessage: string, okTxt: string, cancelTxt: string, callbackFn): Observable<boolean> {
    return fromPromise(
      mobiscroll.confirm({
        message: confirmationMessage,
        okText: okTxt,
        cancelText: cancelTxt,
        theme: 'ios',
        callback: callbackFn
      })
    );
  }
}
