import {FormArray, FormGroup} from '@angular/forms';

export class GenericValidator {
  constructor(private _validationMessages: {
    [key: string]: {
      [key: string]: string
    }
  }) {
  }

  processMessages(container: FormGroup | FormArray): any {
    const messages = {};
    if (container.errors) {
      for (const errorKey in container.errors) {
        if (container.errors.hasOwnProperty(errorKey)) {
          const m = container.errors[errorKey];
          Object.keys(m).map(
            key => {
              messages[errorKey] = this._validationMessages[errorKey][key];
            }
          );
        }
      }
    }
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const c = container.controls[controlKey];
        if (c instanceof FormArray) {
          const fAcontrols = c.controls;
          for (let i = 0; i < fAcontrols.length; i++) {
            const childMessages = this.processMessages(fAcontrols[i] as FormGroup);
            Object.assign(messages, childMessages);
          }
        }
        if (c instanceof FormGroup) {
          const childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {
          if (this._validationMessages[controlKey]) {
            messages[controlKey] = '';
            if (c.errors) {
              Object.keys(c.errors).map(
                messageKey => {
                  if (this._validationMessages[controlKey][messageKey]) {
                    messages[controlKey] += this._validationMessages[controlKey][messageKey] + '';
                  }
                }
              );
            }
          }
        }
      }
    }
    return messages;
  }
}
