<section class="sub-page-container">
  <div class="sub-page">
    <div class="iframe-container">
      <iframe [src]="url | safe"
              (load)="onFrameLoad()"
              height="150%"
              width="150%" *ngIf="activeTab"></iframe>
    </div>
  </div>
</section>

