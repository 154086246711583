import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CalendarModule} from 'primeng/calendar';
import {
  CheckoutPickupDateFormat,
  DateFormatDDMMYY, DateToString,
  DayAndMonth,
  MinPickupDateFormat,
  MinutesTransform, Numberformat,
  PickupDateFormat,
  PriceFormat
} from './pipes';
import {PageheaderComponent} from './components';
import {
  MainComponent,
  StoreHeaderComponent,
  ItemListComponent,
  LoaderComponent,
  UserVerificationComponent,
  ComingSoonComponent
} from './components';
import {MbscModule} from '@mobiscroll/angular';
import {SafePipe} from './pipes/safe.pipe';
import { IframeContainerComponent } from './components/iframe-container/iframe-container.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ServiceNotAvailableModalComponent } from './components/service-not-available-modal/service-not-available-modal.component';
import { CartVerificationComponent } from './components/cart-verification/cart-verification.component';


@NgModule({
  declarations: [
    PageheaderComponent,
    ItemListComponent,
    PriceFormat,
    StoreHeaderComponent,
    DayAndMonth,
    MinutesTransform,
    DateFormatDDMMYY,
    PickupDateFormat,
    CheckoutPickupDateFormat,
    Numberformat,
    MainComponent,
    LoaderComponent,
    UserVerificationComponent,
    MinPickupDateFormat,
    DateToString,
    SafePipe,
    IframeContainerComponent,
    ErrorPageComponent,
    ServiceNotAvailableModalComponent,
    CartVerificationComponent,
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MbscModule,
    CalendarModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ItemListComponent,
    PriceFormat,
    PageheaderComponent,
    StoreHeaderComponent,
    MbscModule,
    DayAndMonth,
    DateFormatDDMMYY,
    PickupDateFormat,
    CheckoutPickupDateFormat,
    Numberformat,
    MinutesTransform,
    LoaderComponent,
    MinPickupDateFormat,
    DateToString,
    CalendarModule
  ],
  providers: [PriceFormat, SafePipe]
})
export class SharedModule {
}
