import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TokenService} from './token.service';
import {ShopOrderDetails} from "../../shared/interface/shop.interface";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private _apiService: ApiService) {
  }

  getAssignedKardex(): Observable<any> {
    const url = `customer/assignedShop`;
    return this._apiService.get(url).pipe(
      map(
        res => res['kardex']
      )
    );
  }
  getShopOrderDetails(): Observable<ShopOrderDetails> {
    const shopId = TokenService.storeId;
    const url = `customer/shop/details/${shopId}`;
    return this._apiService.get(url).pipe(
        map(
            res => res['data']
        )
    )
  }

  changeCartStatusOnRedirect(): Observable<object> {
    const cartId = TokenService.cartId;
    const url = `customer/redirect`;
    const params = {
      paymentStatus: TokenService.paymentResult,
      cartId
    };
    return this._apiService.post(url, ApiService.CreateFormData(params)).pipe(
      map(
        res => res['data']
      )
    );
  }
}
