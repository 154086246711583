import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../../core/services';

@Component({
  selector: 'coop-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  pageTitle: string = 'Mad ud af huset';
  constructor(private _headerService: HeaderService) { }

  ngOnInit(): void {
    this._setHeader();
  }

  private _setHeader(): void {
    this._headerService.setHeader(this.pageTitle, false, 'none', false);
  }

}
