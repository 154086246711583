import {Pipe, PipeTransform} from '@angular/core';
import {Price} from '../interface';

@Pipe({
    name: 'priceFormat'
})
export class PriceFormat implements PipeTransform {
    transform(inputPrice: any): Price {
        const formattedPrice: Price = {
            base: '',
            sup: ''
        };
        if (typeof inputPrice === 'string') {
            const price = inputPrice.split(',');
            formattedPrice.base = price[0];
            formattedPrice.sup = price[1];
            return formattedPrice;
        }
        return inputPrice;
    }
}
