import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormControlName, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MbscCalendarOptions} from '@mobiscroll/angular';
import * as moment from 'moment';
import {fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, takeWhile} from 'rxjs/operators';
import {AppRouteService, CartService, ErrorhandlerService, HeaderService, TokenService} from '../core/services';
import {emailValidator, GenericValidator, validatePhone} from '../core/validators';
import {CustomerMessages} from '../order/data/validation.message';
import {FormattedShopHour, ShopDetails, ShopHour} from '../order/interface';
import {OrderService} from '../order/services';
import {DisplayMessage, ValidationMessage} from '../shared/interface';
import {ShopOrderDetails} from '../shared/interface/shop.interface';
import {DateToString} from '../shared/pipes';
import {pickupdata} from './data';


interface MinMaxPickUpDate {
  min: moment.Moment;
  max: moment.Moment;
}
@Component({
  selector: 'coop-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
  providers: [DateToString]
})
export class AccessibilityComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, {read: ElementRef}) formInputElements: ElementRef[];
  pageTitle: string = 'Kasse';
  currentUrl;
  dateSetting: MbscCalendarOptions = {
    controls: ['calendar', 'time'],
    theme: 'ios',
    returnFormat: 'iso8601',
    dateFormat: 'yy-mm-dd',
    timeFormat: 'H:ii',
    lang: 'da',
    steps: {
      minute: 15
    },
    display: 'inline',
    onInit(event: any, inst: any): void {
      inst.show();
    },
    onShow: (event: { target: HTMLElement; valueText: string }, inst: any) => this._setUpDateTime(inst)
  };
  agreedOne: boolean = false;
  agreedTwo: boolean = false;
  customerForm: FormGroup;
  displayMessage: DisplayMessage = {};
  isProcessing: boolean = false;
  formSaved: boolean = false;
  shopDetails: ShopHour[];
  minPickupDate;
  maxPickupDate;
  shopHours: FormattedShopHour[] = [];
  shopOrderDetails: ShopOrderDetails;
  coopPrivacyPolicy = 'https://ansvarlighed.coop.dk/ansvarlig-arbejdsplads/privatlivspolitik/';
  termsAndCondition = 'https://www.kampagner.coop.dk/muah-salgs-og-leveringsbetingelser/';

  private _subscriptionState: boolean = true;
  private readonly _validationMessages: ValidationMessage;
  private _validator: GenericValidator;

  constructor(private _formBuilder: FormBuilder,
              private _errorHandlerService: ErrorhandlerService,
              private _headerService: HeaderService,
              private _routerService: AppRouteService,
              private _orderService: OrderService,
              private _activatedRoute: ActivatedRoute,
              private _dateToString: DateToString,
              private _router: Router,
              private _cartService: CartService) {
    this._validationMessages = CustomerMessages;
    this._validator = new GenericValidator(this._validationMessages);
  }

  ngOnInit(): void {
    this._initComponent();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(...[this.customerForm.valueChanges, controlBlurs])
      .pipe(
        debounceTime(800),
        takeWhile(() => this._subscriptionState)
      )
      .subscribe(() => {
        if (Object.keys(this.displayMessage).length && this.displayMessage.constructor === Object) {
          this.displayMessage = this._validator.processMessages(this.customerForm);
        }
      });
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  agreeTermOne(): void {
    this.agreedOne = !this.agreedOne;
  }

  agreeTermTwo(): void {
    this.agreedTwo = !this.agreedTwo;
  }

  goToPayment(): void {
    if (this.customerForm.status === 'INVALID') {
      this.displayMessage = this._validator.processMessages(this.customerForm);
      this._handleError('');
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.customerForm.value.pickupDate = this._dateToString.transform(this.customerForm.value.pickupDate);
      this.customerForm.value.alcoholicTerms = this.agreedOne;
      this.customerForm.value.coopTerms = this.agreedTwo;
      this._orderService.checkout(this.customerForm.value)
        .pipe(
          takeWhile(() => this._subscriptionState)
        )
        .subscribe(
          res => this._success(res),
          err => this._handleError(err)
        );
    }
  }
  /*    validateNumber(event) {
          const keyPressed = event.key;
          const keyCode = keyPressed.charCodeAt(0);
          if (keyCode === 66) {
              return;
          }
          const regex = /^[0-9.+,]+$/;
          if (!regex.test(keyPressed)) {
              event.preventDefault();
          }
      }*/

  private _initComponent(): void {
    this._setHeader();
    this.customerForm = this._formBuilder.group({
      customerName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required]],
      pickupDate: ['', [Validators.required]],
      alcoholicTerms: [false],
      coopTerms: [false]
    }, {
      validators: Validators.compose([validatePhone, emailValidator])
    });
    this.shopDetails = pickupdata;
    this._setDefaultTimeOnCreate();
    this._setShopData();
  }
  private _setShopData(): void {
    this.shopOrderDetails = {
      id: TokenService.storeId,
      name: TokenService.storeName,
      address: TokenService.storeAddress,
      phoneNumber: TokenService.storeNumber,
      cvr: TokenService.storeCvr
    };
  }

  private _setDefaultTimeOnCreate(): void {
    const pickUpDates = this._getMinMaxPickupDate(this.shopDetails);
    this.minPickupDate = pickUpDates.min;
    this.maxPickupDate = pickUpDates.max;
    this.dateSetting.min = this.minPickupDate;
    this.dateSetting.max = this.maxPickupDate;
    this.shopHours = this._getInvalidShopHours(this.shopDetails, this.shopHours);
    this.dateSetting.invalid = this.shopHours;
    this.customerForm.patchValue({
      pickupDate: this.minPickupDate
    });
  }

  private _getMinMaxPickupDate(pickUpDates): MinMaxPickUpDate {
    const pickupDate = this._concatTime(pickUpDates[0].date, pickUpDates[0].start);
    const shopHoursLength = pickUpDates.length;
    const maxPickUpDate = this._concatTime(pickUpDates[shopHoursLength - 1].date, pickUpDates[shopHoursLength - 1].end);
    return {min: this._timeIntervalFormat(pickupDate), max: this._timeIntervalFormat(maxPickUpDate)};
  }

  private _getInvalidShopHours(pickUpDates, shopHours): FormattedShopHour[] {
    pickUpDates.forEach(selectedDate => {
      if (!selectedDate.holiday) {
        const startingShopHour: FormattedShopHour = {
          d: moment(selectedDate.date).toDate(),
          start: '00:00',
          end: this._calulateMinMaxDate('min', selectedDate.date, selectedDate.start)
        };
        const endingShopHour: FormattedShopHour = {
          d: moment(selectedDate.date).toDate(),
          start: this._calulateMinMaxDate('max', selectedDate.date, selectedDate.end),
          end: '23:59'
        };
        shopHours = [...shopHours, startingShopHour, endingShopHour];
      }
      if (selectedDate.holiday) {
        const holidayHour = {
          start: moment(selectedDate.date).toDate(),
          end: moment(selectedDate.date).toDate()
        };
        shopHours = [...shopHours, holidayHour];
      }
    });
    return shopHours;
  }

  private _concatTime(date: string, time: string): moment.Moment {
    const concatedTime = moment(date + ' ' + time);
    return concatedTime;
  }

  private _calulateMinMaxDate(timeStatus: string, date, time): string {
    let newDate = this._concatTime(date, time);
    if (timeStatus === 'min') {
      newDate = newDate.subtract(15, 'minutes');
    }
    if (timeStatus === 'max') {
      newDate = newDate.add(15, 'minutes');
    }
    return newDate.format('HH:mm');
  }

  private _timeIntervalFormat(date): moment.Moment {
    let newDate = date;
    const min = moment(date).minutes();
    if (min > 0) {
      const newMin = (Math.ceil(min / 15) * 15) % 60;
      const toBeAddedMin = newMin - min;
      if (newMin === 0) {
        newDate = moment(date).add(1, 'hours').minute(0).second(0);
      }
      if (newMin !== 0) {
        newDate = moment(date).add(toBeAddedMin, 'minutes').second(0);
      }
    }
    return newDate;
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst): void {
    const dateId = inst.element.id;
    // set start date at today's time 8:00
    if (dateId.indexOf('pickup') === 0 && !this.customerForm.value.pickupDate) {
      this.customerForm.patchValue({
        pickupDate: moment().toDate()
      });
    }
  }

  private _success(res): void {
    this._cartService.postTestRoute(res.paymentReference, 'payment Ref at checkout').pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe(() => {}, err => {});
    this.isProcessing = false;
    this.formSaved = true;
    const redirectUrl = `https://coopshopnativetest.quickinfo.dk/?storeKardex=${TokenService.storeKardex}&openTab=LocalFood`;
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    window.location.href = `https://static.scan-pay.acs.coop.dk/quickcoop?paymentId=${res.paymentReference}&redirectUrl=${encodedRedirectUrl}`;
  }

  private _handleError(error): void {
    this._errorHandlerService.handleError(error || {code: 400}, 'cart');
    this.isProcessing = false;
    if (error !== '') {
      const route = this._routerService.getPreviousUrl();
      this._router.navigate([route]).then();
    }
  }

  private _setHeader(): void {
    this._headerService.setHeader(this.pageTitle, false, 'white', false);
  }

}
